import React from "react"

import Layoutcs from "../../components/layoutcs/layoutcs"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Clientlogos from "../../components/clientlogos/clientlogos"
import Footer from "../../components/footer/footer"

import "../index.scss"
import "../about-us.scss"

import ImgAboutUs13 from "../../images/about-us/about-us-13.jpg"
import ImgAboutUs14 from "../../images/about-us/about-us-14.jpg"
import ImgAboutUs15 from "../../images/about-us/about-us-15.jpg"
import ImgAboutUs16 from "../../images/about-us/about-us-16.jpg"
import ImgAboutUs17 from "../../images/about-us/about-us-17.jpg"
import ImgAboutUs20 from "../../images/about-us/about-us-20.jpg"
import ImgAboutUs21 from "../../images/about-us/about-us-21.jpg"
import ImgAboutUs23 from "../../images/about-us/about-us-23.jpg"

import ImgAboutUsReference1 from "../../images/client-logo/slsp.svg"
import ImgAboutUsReference2 from "../../images/client-logo/peugeot.svg"
import ImgAboutUsReference3 from "../../images/client-logo/innogy.svg"

import VideoBg from "../../videos/ftrs-about-us.mp4"

const Aboutus = () => (

    <Layoutcs>
        <SEO title="O nás" description="Jsme skupina geeků se spoustou zkušeností s vývojem digitálních produktů." />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content">

            <Row>
                <Col xs="12" lg="8" xl="6" className="pb-5">
                    <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Vyvíjíme skvělé digitální produkty</h1>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Důvěra</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Již více než 10&nbsp;let vytváříme webové a&nbsp;mobilní aplikace, které v&nbsp;hojném počtu používají přední společnosti na&nbsp;trhu z&nbsp;různých tržních odvětví.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Technologická zkušenost</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Během vývoje různých produktů jsme vyřešili mnoho technologických výzev a&nbsp;získali přehled a&nbsp;zkušenost, díky kterým jsme schopni nová zadání řešit rychle a&nbsp;úspěšně.</p>

					<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Transparentnost</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Mějte plnou kontrolu nad tím, co se děje. Díky naší transparentní komunikaci, procesům a&nbsp;reportingu v&nbsp;reálném čase.</p>

                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--underline mt-5">Osobní projektový manažer</h2>
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Budete mít veškerou pozornost, kterou potřebujete. S&nbsp;jednou kontaktní osobou na&nbsp;naší straně vždy dostanete rychlou odpověď na&nbsp;jakýkoli dotaz nebo požadavek. Náš flexibilní tým seniorních vývojářů je připraven na&nbsp;úpravy plánu a&nbsp;škálování kapacit v&nbsp;co nejkratším čase.</p>

                </Col>
                <Col xs="12" lg="4" xl="6" className="pb-5">
                    <div className="ftrs-sidepool">
                        <div className="ftrs-sidepool__container">
                        <div className="ftrs-sidepool__container">
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs15} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs14} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs13} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs16} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs17} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs20} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs23} className="ftrs-sidepool__img" alt="" />
                            </div>
                            <div className="ftrs-sidepool__item" data-sal="fade" data-sal-delay="300" data-sal-duration="1500">
                                <img src={ImgAboutUs21} className="ftrs-sidepool__img" alt="" />
                            </div>
                        </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>

        <Clientlogos lang='cs'></Clientlogos>

        <Container>
            <div className="ftrs-testimonials">
                <Row>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference1} className="ftrs-testimonials__img ftrs-testimonials__img--horizontal" alt="" />
                            <p className="ftrs-testimonials__name">Slovenská sporiteľňa</p>
                            <p className="mt-0 mb-0">člen Erste Group Bank AG</p>
                            <p className="mt-0 mb-0">největší komerční banka na&nbsp;Slovensku</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"Hlavné dôvody, prečo budeme najbližšie roky pokračovať s&nbsp;feedTRACK-om, sú vysoká návratnosť dotazníkov, prehľadné užívateľské rozhranie a&nbsp;najmä flexibilita - či&nbsp;už ide o&nbsp;dátové prenosy (agentúra rozumie dátam a&nbsp;IT celkovo), štruktúru dotazníkov a&nbsp;mailov (plne podľa našich požiadaviek), sledovanie úloh, či&nbsp;o&nbsp;analýzy jednotlivých pobočiek, oblastí a&nbsp;regiónov.</p>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">Ak by sme mali povedať hlavný dôvod, prečo u&nbsp;nás vyhral feedTRACK, tak&nbsp;by sa to dalo zhrnúť jedným slovom, a&nbsp;to profesionalita."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference2} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">P Automobil Import</p>
                            <p className="mt-0 mb-0">oficiální Peugeot importér</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"Líbí se nám, že&nbsp;je AUTO&nbsp;eCIM uživatelsky jednoduchý a&nbsp;praktický on-line systém. Týdenní aktualizace nám umožňují vytvořit si detailní přehled o&nbsp;novinkách na&nbsp;trhu."</p>
                    </Col>
                    <Col xs="12" lg="8" xl="4" className="pb-5">
                        <div data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__signature">
                            <img src={ImgAboutUsReference3} className="ftrs-testimonials__img ftrs-testimonials__img--vertical" alt="" />
                            <p className="ftrs-testimonials__name">Innogy</p>
                            <p className="mt-0 mb-0">člen E.ON</p>
                        </div>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">"dMONITOR je pro&nbsp;nás užitečný nástroj. Díky dMONITORu nyní máme ucelený přehled o&nbsp;komunikačních aktivitách na&nbsp;českém energetickém trhu, který jsme dříve v&nbsp;oblasti přímé komunikace postrádali.</p>
                        <p data-sal="fade" data-sal-duration="1500" className="ftrs-testimonials__text text-basic">Oceňujeme uživatelsky přívětivou internetovou aplikaci s&nbsp;rozsáhlými možnosti filtrování v&nbsp;bohatém archivu."</p>
                    </Col>
                </Row>

            </div>
        </Container>

		<Footer lang='cs'></Footer>

    </Layoutcs>

)

export default Aboutus